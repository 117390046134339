// Libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
// Views
import FinalizeChecklist from './finalize/FinalizeChecklist';
// Constants
import { ALERT_FILTER_CONSTANTS, FINALIZE_ALERTS } from '../../constants/constants';
import { ENROLLED_STATUSES } from '../../constants/statuses';
// Hooks
import useLoadPatientCarePlan from '../../hooks/services/useLoadPatientCarePlan';
import useLoadPatientInfo from '../../hooks/services/useLoadPatientInfo';
import useLoadAlerts from '../../hooks/services/useLoadAlerts';
import useLoadFinalizeAlerts from '../../hooks/services/useLoadFinalizeAlerts';

export const Finalize = () => {
  const { id: patientId } = useParams();
  const [alerts, setAlerts] = useState([]);
  const { refetch } = useLoadPatientCarePlan({ patientId, shouldPatientBeUpdated: true });
  const { alertsFlightPlan } = useLoadAlerts({
    patientId, type: ALERT_FILTER_CONSTANTS.MISSING_TT_ENTRY.name,
  });
  useLoadPatientInfo({ patientId });
  const { patient, user: { reviewRequired } } = useSelector(state => state);
  const [carePlanHasNoGoalsOrInterventions, setCarePlanHasNoGoalsOrInterventions] = useState(false);
  const billingIsOnHold = patient && patient.billing && patient.billing.sendingStatus === 'ON_HOLD';
  const isCSDoNotBill = patient && patient.billingSettings && patient.billingSettings.doNotBill && patient.status === 'CS';
  const { finalizeAlerts, refetch: refetchAlerts } = useLoadFinalizeAlerts({ patientId });

  if (carePlanHasNoGoalsOrInterventions) {
    if (!alerts.some(a => a.id === 5)) {
      setAlerts([...alerts, { id: 5, variant: 'warning', message: 'Care plan has no goals or interventions' }]);
    }
  }

  if (billingIsOnHold || isCSDoNotBill) {
    if (!alerts.some(a => a.id === 2)) {
      setAlerts([
        ...alerts, {
          id: 2, variant: 'danger', message: 'Billing is on hold for this patient', icon: 'bi-slash-circle',
        },
      ]);
    }
  }

  if (reviewRequired) {
    if (!alerts.some(a => a.id === 6)) {
      setAlerts([
        ...alerts, {
          id: 6, variant: 'warning', message: 'Supervisor must review Care Plan before finalization',
        },
      ]);
    }
  }

  if (alertsFlightPlan.patientAlertsInfo && alertsFlightPlan.patientAlertsInfo.length > 0) {
    const timeTrackingAlerts = [];
    const ttAlerts = alertsFlightPlan.patientAlertsInfo[0];
    if (!alerts.some(a => a.id > 6) && ttAlerts.alerts.length > 0) {
      ttAlerts.alerts.forEach((element, index) => {
        timeTrackingAlerts.push({
          id: 7 + index, variant: 'danger', message: element.description,
        });
      });
      setAlerts([...alerts, ...timeTrackingAlerts]);
    }
  }

  useEffect(() => {
    if (ENROLLED_STATUSES.includes(patient.status)) {
      const activeAlerts = [];
      const finalizeAlertsIds = Object.values(FINALIZE_ALERTS)
        .filter(val => val.active)
        .map(val => val.active && val.id);
      Object.keys(finalizeAlerts).forEach((key) => {
        if (finalizeAlerts[key] && FINALIZE_ALERTS[key] && FINALIZE_ALERTS[key].active) {
          activeAlerts.push(FINALIZE_ALERTS[key]);
        }
      });
      // find non-finalized alerts from alerts....
      const nonFinalizeAlerts = alerts.filter(a => !finalizeAlertsIds.includes(a.id));
      // set alerts to non-finalized alerts + updated active finalized alerts
      setAlerts([...nonFinalizeAlerts, ...activeAlerts]);
    }
  }, [finalizeAlerts]);

  useEffect(() => {
    setCarePlanHasNoGoalsOrInterventions(patient.hasInterventions === 0 && patient.hasGoals === 0);
  }, [patient]);

  return (
    <div className="patient-profile h-100 overflow-auto pr-3">
      <h4 className="text-uppercase text-left">Finalize</h4>
      <div className="row text-left no-gutters pb-3 mb-3">
        <div className="col-12 mb-2">
          {
            alerts.sort((a, b) => a.id - b.id).map(item => (
              <Alert key={`alert_${item.id}`} variant={item.variant} className="mb-1 py-2 px-3">
                <i className={`bi ${item.icon ? item.icon : 'bi-exclamation-triangle'} mr-2`} />
                <span className="ml-2" data-test="finalize_alerts">{item.message}</span>
              </Alert>))}
        </div>
        <FinalizeChecklist
          patientId={patientId}
          refetchLoadPatient={refetch}
          refetchFinalizeAlerts={refetchAlerts}
        />
      </div>
    </div>
  );
};

export default Finalize;
